input,
textarea{
	width: 100%;
	height: auto;
	min-height: 44px;
	border: 2px solid darken(white, 20);
	outline: none;
	padding: 8px 10px;
	font-size: rem(16);
	line-height: rem(24);
	transition: all 0.2s ease;
	background-color: transparent;

	&:focus{
		border-color: $color-1;
	}

	.error &{
		border-color: $error;
	}

	.success &{
		border-color: $success;
	}
}

textarea{
	max-width: 100%;
	min-height: 150px;
}

select{
    background: transparent;
    background-image: none;
    appearance: none;
    box-shadow: none;
    width: 100%;
	height: auto;
	min-height: 44px;
	border: 2px solid darken(white, 20);
	outline: none;
	padding: 8px 10px;
	font-size: rem(16);
	line-height: rem(24);
	border-radius: 3px;
	transition: all 0.2s ease;
	background-color: transparent;

    &::-ms-expand{
    	display: none;
    }

    &:focus{
		border-color: $color-1;
	}

	.error &{
		border-color: $error;
	}

	.success &{
		border-color: $success;
	}
}

button{
	border: 0;
	outline: 0;
}

.form__label{
	display: block;
	font-size: rem(13);
	font-weight: 700;
	color: $font-color-1;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	margin-bottom: 4px;
}

.form__options{
	&--inline{
		display: flex;
		flex-wrap: wrap;
		margin: -3px -12px;

		> *{
			padding: 3px 12px;
		}
	}
}

.form__option{
	input{
		display: none;
	}

	label{
		display: inline-flex;
		justify-content: flex-start;
		align-items: center;
	}

	input:checked + label{
		.form__input--checkbox{
			&:before,
			&:after{
				opacity: 1;
			}
		}

		.form__input--radiobutton{
			&:before{
				opacity: 1;
			}
		}
	}
}

.form__input--checkbox{
	position: relative;
	display: inline-flex;
	margin: 0 8px 0 0;
	width: 18px;
	height: 18px;
	border: 2px solid darken(white, 20);

	&:before,
	&:after{
		content: '';
		display: block;
		width: 10px;
		height: 2px;
		background-color: $color-2;
		position: absolute;
		top: 50%;
		left: 50%;
		opacity: 0;
	}

	&:before{
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:after{
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}

.form__input--radiobutton{
	position: relative;
	display: inline-flex;
	margin: 0 8px 0 0;
	width: 18px;
	height: 18px;
	border-radius: 9px;
	border: 2px solid darken(white, 20);

	&:before{
		content: '';
		display: block;
		width: 6px;
		height: 6px;
		border-radius: 5px;
		background-color: $color-2;
		position: absolute;
		top: 50%;
		left: 50%;
		opacity: 0;
		transform: translate(-50%, -50%);
	}
}

.form__input--select{
	position: relative;
	width: 100%;
}

.form__input-icon--select{
	position: absolute;
	top: 50%;
	right: 12px;
	transform: translateY(-50%);
	width: 16px;
	height: 16px;

	&:before,
	&:after{
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		content: '';
		width: 0;
		height: 0;
	}

	&:before{
		top: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid darken(white, 50);
	}

	&:after{
		bottom: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 6px solid darken(white, 50);
	}
}

.form__input--date{
	position: relative;
	z-index: 0;

	input{
		padding-right: 44px;
	}
}

.form__input-icon--date{
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
	width: 44px;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;

	.icon--calendar{
		width: 16px;
		height: 16px;
		fill: darken(white, 50);
	}
}

#row-url{
	display: none;
}

.form__fieldset{
	display: flex;
	flex-wrap: wrap;
	margin: -$gutter/2;
}

.form__row{
	flex: 1 0 auto;
	width: 100%;
	padding: $gutter/2;

	&--text{
		width: 50%;
	}

	&:first-child{
		margin-top: 0;
	}
}

.feedback{
	height: 0;
	overflow: hidden;
	opacity: 0;
	transition: all 0s 0.2s, opacity 0.2s ease;

	&.error,
	&.success{
		height: auto;
		overflow: auto;
		opacity: 1;
		transition: opacity 0.2s ease;
	}
}

.feedback--small{
	border-radius: 3px;
	font-size: rem(16);
	font-weight: 700;
	
	&.error{
		color: $error;
	}

	&.success{
		color: $success;
	}

	&.error,
	&.success{
		padding: $gutter/2;
	}
}

@media screen and (max-width: 639px){
	.form__row{
		&--text{
			width: 100%;
		}
	}
}