.page--offline{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	padding: $gutter*4 0;
	background-color: $color-2;

	.wysi{
		color: white;

		h1,
		h2,
		h3{
			color: white;
		}

		a{
			color: $color-1;
		}
	}
}

.section--offline{
	width: 100%;

	.container{
		max-width: 768px;
		display: flex;
	}

	.grid{
		margin: -($gutter);
	}

	.col{
		padding: $gutter;

		&:first-child{
			display: flex;
		}
	}
}

.offline__logo{
	display: block;
	width: 360px;
	margin: 8px 40px 0 0;
}

.offline__newsletter{
	padding: $gutter*2;
	background-color: darken($color-2, 3);
}

@media screen and (max-width: 1199px){

}

@media screen and (max-width: 1023px){
	.page--offline{
		padding: $gutter*2 0;
	}

	.section--offline{
		.col{
			&:first-child{
				flex-direction: column;
				align-items: center;
			}
		}
	}

	.offline__logo{
		margin: 0 0 40px 0;
	}
}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){

}

@media screen and (max-width: 639px){

}

@media screen and (max-width: 479px){
	
}