.footer{
	padding: 80px 0;
	background-color: $color-2;

	hr{
		display: flex;
		border-top: 1px solid lighten($color-2, 10);
		width: 100%;
		height: 0;
	}

	.grid{
		align-items: flex-start;
		margin: -$gutter;
	}

	.col{
		padding: $gutter;

		&:first-child{
			display: flex;
			align-items: center;
		}

		&:last-child{
			display: flex;
			justify-content: space-between;
		}
	}

	.wysi{
		color: white;

		h1,
		h2,
		h3{
			color: white;
		}
	}
}

.footer__logo{
	display: block;
	width: auto;
	height: 100px;
	margin-right: 50px;

	img{
		max-width: 100%;
		width: auto;
		height: 100px;
	}
}

.footer__copyrights{
	display: inline-flex;
	font-size: rem(12);
	color: darken(white, 50);
}

.footer__credits{
	display: inline-flex;
	font-size: rem(12);
	color: darken(white, 50);

	a{
		transition: all 0.1s ease;
		
		&:hover{
			color: white;
		}
	}
}

.nav--social{
	margin-left: auto;

	ul{
		display: flex;
		padding: 0;
		margin: 0;
	}

	li{
		display: inline-flex;
		margin: 0 2px;

		&:first-child{
			margin-left: 0;
		}

		&:last-child{
			margin-right: 0;
		}
	}

	a{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		transition: all 0.1s ease;

		&:hover{
			background-color: $color-1;
		}
	}

	.icon{
		width: 20px;
		height: 20px;
		fill: white;
	}
}

.nav--footer{
	ul{
		display: flex;
		padding: 0;
		margin: 0;
	}

	li{
		display: inline-flex;
		margin: 0 $gutter;
	}

	a{
		font-size: rem(14);
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		color: white;
		transition: all 0.1s ease;
	}

	.is-active{
		a{
			font-weight: bold;
			color: $color-1;
		}
	}
}

.form--newsletter{
	margin-top: 1.5em;

	::placeholder{
		font-size: rem(14);
		font-weight: bold;
		color: white;
	}

	input,
	textarea{
		border-color: lighten($color-2, 15);
		color: white;

		&:focus{
			border-color: $color-1;
		}
	}

	.error input{
		border-color: lighten($error, 10);
	}

	.success input{
		border-color: lighten($success, 10);
	}
}

@media screen and (max-width: 1199px){

}

@media screen and (max-width: 1023px){
	.footer{
		padding: 60px 0;
		
		.col{
			&:first-child{
				flex-direction: column;
			}
		}
	}

	.footer__logo{
		margin: 0;
		margin-bottom: 50px;
	}

	.nav--social{
		margin-left: 0;
	}

	.footer__logo{
		margin-right: 0;
	}
}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){
	.footer{
		.col{
			&:last-child{
				flex-direction: column;
				align-items: center;
			}
		}
	}

	.nav--footer{
		ul{
			flex-direction: column;
		}

		li{
			justify-content: center;
		}
	}
}

@media screen and (max-width: 639px){

}

@media screen and (max-width: 479px){
	
}