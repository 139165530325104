/*
 * Fonts
 */
$base-font-family: 'Montserrat', sans-serif;
$base-font-size: 15px;
$base-font-line-height: 30px;
$base-font-color: #565656;

@import url('https://fonts.googleapis.com/css?family=Great+Vibes');
$montserrat: 'Montserrat', sans-serif;
$raleway: 'Great Vibes', serif;

/*
 * Colors
 */
$color-1: #71625b;
$color-2: #232320;
$color-3: #e3e2d7;

$font-color-1: #232320;
$font-color-2: #71625b;
$font-color-3: #efefef;

$error: #df5555;
$success: #429e62;

/*
 * Gutters
 */
$gutter: 20px;