.section--search{
	padding: $gutter 0;
	background-color: $color-1;
}

.section--dealers{
	padding: $gutter*4 0;	
}

.section--feedback{
	display: none;
	padding: $gutter 0;

	.wysi{
		font-size: rem(18);
		text-align: center;
		color: $font-color-1;
	}
}

.dealers__overview{
	margin-top: $gutter*2;
	
	.grid{
		justify-content: flex-start;
		align-items: stretch;
	}

	.col{
		display: flex;
		flex: 0 0 auto;
	}
}

.dealer__block{
	width: 100%;
	padding: $gutter*2;
	background-color: darken(white, 3);

	h3{
		font-family: $montserrat;
		font-size: rem(16);
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		color: $font-color-1;

		span{
			position: relative;
			top: -2px;
			display: inline-block;
			margin-left: 8px;
			padding: 4px;
			background-color: $color-1;
			font-size: rem(12);
			line-height: 1em;
			color: white;
		}
	}

	strong{
		color: $color-1;
	}
}

.dealer__infowindow{
	padding: $gutter;

	.wysi{
		font-size: rem(15);
		line-height: rem(24);

		h3{
			span{
				font-family: $montserrat;
				position: relative;
				top: -3px;
				display: inline-block;
				margin-left: 10px;
				padding: 4px;
				background-color: $color-1;
				font-size: rem(12);
				line-height: 1em;
				color: white;
			}
		}		
	}
}

.search__block{
	form{
		display: flex;
	}

	.wysi{
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		flex: 0 0 auto;
		margin-right: 40px;
		font-family: $montserrat;
		letter-spacing: 0.1em;
		font-size: rem(18);
		text-transform: uppercase;
		color: white;
	}

	input{
		border-radius: 0;
		border-color: transparent;
		background-color: transparent;
		border: 1px solid white;
		margin-right: $gutter/2;
		color: white;

		&::placeholder{
			color: white;
		}
	}

	.btn{
		flex: 0 0 auto;
		border-radius: 0 !important;
	}
}

@media screen and (max-width: 767px){
	.section--search{
		padding: 40px 0;
	}

	.search__block{
		form{
			flex-direction: column;
			align-items: flex-start;
		}

		.wysi{
			justify-content: flex-start;
			width: 100%;
			margin: 0;
		}

		input{
			margin: $gutter/2 0;
			max-width: 768px;
		}

		.btn{
			margin-left: auto;
		}
	}
}

@media screen and (max-width: 767px){
	.search__block{
		form{
			align-items: stretch;
		}

		.wysi{
			justify-content: center;
		}

		.btn{
			margin: 0;
			justify-content: center;
		}
	}
}