.nav--pagination{
	width: 100%;
}

.nav__list{
	display: flex;
	justify-content: center;
	list-style: none;
	padding: 0;
	margin: 0;
}

.nav__item{
	margin: 0 3px;
}

.nav__item:not(.nav__item--next):not(.nav__item--prev) .nav__link{
	display: block;
	width: 44px;
	height: 44px;
	border-radius: 22px;
	background-color: $color-3;
	font-weight: bold;
	text-align: center;
	line-height: 44px;
	color: white;
	transition: all 0.2s ease;

	&:hover,
	&.active{
		background-color: $color-1;
	}
}

.nav__item--next .nav__link,
.nav__item--prev .nav__link{
	height: 44px;
	padding: 0 20px;
	font-weight: bold;
	line-height: 44px;
	transition: all 0.2s ease;

	&:hover{
		color: darken($font-color-2, 20);
	}
}