.section--blocks{
	.container{
		display: flex;
		flex-direction: column;
		
		> *{
			margin-top: $gutter*4;
		}
	}

	.block--text{
		&:last-child{
			margin-bottom: $gutter*4;
		}
	}

	.block--image{
		width: 100%;

		&:last-child{
			margin-bottom: $gutter*4;
		}
	}

	.block--image-left{
		&:last-child{
			margin-bottom: $gutter*4;
		}

		.grid{
			margin: -($gutter*2);
			align-items: flex-start;
		}

		.col{
			padding: $gutter*2;
		}
	}

	.block--image-right{
		&:last-child{
			margin-bottom: $gutter*4;
		}

		.grid{
			margin: -($gutter*2);
			align-items: flex-start;
		}

		.col{
			padding: $gutter*2;
		}
	}

	.block--form{
		&:last-child{
			margin-bottom: $gutter*4;
		}
	}
}

@media screen and (max-width: 1199px){

}

@media screen and (max-width: 1023px){
	.section--blocks{
		.container{
			> *{
				margin-top: $gutter*2;
			}
		}
	
		.block--image-right,
		.block--image-left{
			.grid{
				margin: -$gutter;
			}

			.col{
				padding: $gutter;
			}

			&:last-child{
				margin-bottom: $gutter*2;
			}
		}

		.block--text{
			&:last-child{
				margin-bottom: $gutter*2;
			}
		}

		.block--image{
			&:last-child{
				margin-bottom: $gutter*2;
			}
		}

		.block--form{
			&:last-child{
				margin-bottom: $gutter*2;
			}
		}
	}
}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){

}

@media screen and (max-width: 639px){

}

@media screen and (max-width: 479px){
	
}