.btn{
	display: inline-flex;
	padding: 13px 32px;
	font-family: $montserrat;
	font-size: rem(14);
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	line-height: rem(17);
	letter-spacing: 0.2em;
	color: white;
	border-radius: 0;
	border: 2px solid transparent;
	transition: all 0.2s ease;

	&--color-1{
		background-color: $color-1;

		&:hover{
			background-color: darken($color-1, 10);
		}
	}

	&--color-2{
		background-color: transparent;
		border-color: $color-1;
		color: $font-color-2;

		&:hover{
			background-color: darken($color-1, 10);
			border-color: darken($color-1, 10);
			color: white;
		}
	}

	&--color-3{
		background-color: white;
		color: $font-color-1;

		&:hover{
			background-color: $color-2;
			color: white;
		}
	}
}