.grid-container{
	overflow: hidden;
}

.grid-controls{
	margin: $gutter * 2 0 0;
	display: flex;
	justify-content: center;
}

.grid{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin: -($gutter / 2);
}

.col{
	flex: 1 0 auto;
	padding: ($gutter / 2);

	@for $i from 1 through 100{
		&--#{$i}{
			width: $i + %;
		}
	}
}

@media screen and (max-width: 1199px){
	.col{
		@for $i from 1 through 100{
			&--1199-#{$i}{
				width: $i + %;
			}
		}
	}
}

@media screen and (max-width: 1023px){
	.col{
		@for $i from 1 through 100{
			&--1023-#{$i}{
				width: $i + %;
			}
		}
	}
}

@media screen and (max-width: 959px){
	.col{
		@for $i from 1 through 100{
			&--959-#{$i}{
				width: $i + %;
			}
		}
	}
}

@media screen and (max-width: 767px){
	.col{
		@for $i from 1 through 100{
			&--767-#{$i}{
				width: $i + %;
			}
		}
	}
}

@media screen and (max-width: 639px){
	.col{
		@for $i from 1 through 100{
			&--639-#{$i}{
				width: $i + %;
			}
		}
	}
}

@media screen and (max-width: 479px){
	.col{
		@for $i from 1 through 100{
			&--479-#{$i}{
				width: $i + %;
			}
		}
	}
}