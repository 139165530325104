.section--contact{
	padding: $gutter*4 0;

	.grid{
		margin: -($gutter*2);
		align-items: flex-start;
	}

	.col{
		padding: $gutter*2;
	}

	.block--form{
		margin: $gutter*2 0 0;
	}
}

.contact-info{
	padding: $gutter*2;
	background-color: $color-1;

	h1,
	h2,
	h3{
		font-family: $montserrat;
		font-weight: bold;
		color: white;
	}

	h1{
		font-size: rem(24);
	}

	h2{
		font-size: rem(18);
	}

	h3{
		font-size: rem(16);
	}

	.wysi{
		color: white;
	}
}

.section--map{
	width: 100%;
	height: 480px;
	background-color: white;
}

@media screen and (max-width: 1199px){

}

@media screen and (max-width: 1023px){
	.section--contact{
		padding: $gutter*2 0;

		.grid{
			margin: -$gutter;
		}

		.col{
			padding: $gutter;
		}
	}
}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){

}

@media screen and (max-width: 639px){

}

@media screen and (max-width: 479px){
	
}