.nav--tabs{
	margin: $gutter*2 0;
	overflow: hidden;

	ul{
		padding: 0;
		margin: -1px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	li{
		display: inline-flex;
		padding: 1px;
	}

	a{
		display: flex;
		padding: 13px 32px;
		border: 2px solid transparent;
		font-size: rem(14);
		font-weight: 900;
		text-transform: uppercase;
		line-height: rem(17);
		letter-spacing: 0.2em;
		color: $font-color-1;
		transition: all 0.1s ease;

		&:hover{
			background-color: $color-2;
			color: white;
		}
	}

	.is-active{
		a{
			background-color: $color-1;
			color: white;
		}
	}
}

.section--gallery-overview{
	padding: $gutter*4 0;

	.grid{
		align-items: flex-start;
	}

	.col{
		flex: 0 0 auto;
	}

	.col--33{
		width: 33.33%;
	}

	.wysi{
		text-align: center;
	}
}

.gallery__card{
	display: flex;
	max-width: 640px;

	&:hover{
		img{
			transform: scale3d(1.1, 1.1, 1.1);
		}
	}
}

.gallery__card-image{
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	img{
		transition: all 0.2s ease;
	}
}

@media screen and (max-width: 1199px){

}

@media screen and (max-width: 1023px){
	.section--gallery-overview{
		padding: $gutter*2 0;
		
		.col--1023-50{
			width: 50%;
		}
	}
}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){

}

@media screen and (max-width: 639px){
	.section--gallery-overview{
		.col--639-100{
			width: 100%;
		}
	}
}

@media screen and (max-width: 479px){
	
}