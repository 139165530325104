.slideshow{
	position: relative;
	z-index: 0;
	width: 100%;
	height: 768px;

	ul{
		width: 100%;
		height: 100%;
		display: flex;
		padding: 0;
		margin: 0;
	}

	li{
		width: 100%;
		height: 100%;
		display: flex;
	}

	.flex-viewport{
		display: flex;
		width: 100%;
		height: 100%;
	}
}

.slideshow__slide{
	position: relative;
	z-index: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.slideshow__slide-content{
	display: flex;
	align-items: center;
	width: 100%;
	height: calc(100% - 190px);
	position: relative;
	z-index: 1;

	.wysi{
		max-width: 66%;
	}

	h1{
		font-family: $montserrat;
		font-size: rem(60);
		line-height: 1.2em;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		color: $font-color-2;
	}

	h2{
		font-family: $montserrat;
		font-size: rem(48);
		line-height: 1.2em;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		color: $font-color-2;
	}

	h3{
		font-family: $montserrat;
		font-size: rem(36);
		line-height: 1.2em;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		color: $font-color-2;
	}
}

.slideshow__slide-image{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;
}

.fx-enabled 
.fx-in 
.slideshow__slide-image{
	transform: scale(1.025);
	transition: all 15s ease;
}

.slideshow__control{
	z-index: 99;
	position: absolute;
	top: 50%;
	transform: translate3d(0, -50%, 0);
	width: 40px;
	height: 76px;
	overflow: hidden;
	text-indent: -999em;
}

.slideshow__control-icon{
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	width: 40px;
	height: 76px;
	fill: white;
}

.slideshow__control--next{
	right: 30px;
}

.slideshow__control--prev{
	left: 30px;
}

.section--gallery{
	.grid{
		margin: 0;
		align-items: stretch;
	}

	.col{
		flex: 1 0 auto;
		display: flex;
		padding: 0;
	}

	.hidden{
		display: none;
	}

	a:not(.gallery__cta){
		display: block;
		width: 100%;
		position: relative;

		&:before{
			content: '';
			display: block;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: black;
			opacity: 0;
			transition: all 0.2s ease;
		}

		&:after{
			content: attr(data-label);
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			z-index: 2;
			top: $gutter;
			bottom: $gutter;
			left: $gutter;
			right: $gutter;
			border: 1px solid white;
			font-size: rem(13);
			font-weight: bold;
			text-transform: uppercase;
			letter-spacing: 0.25em;
			color: white;
			opacity: 0;
			transition: all 0.2s ease;
		}

		&:hover{
			&:before{
				opacity: 0.15;
			}

			&:after{
				opacity: 1;
			}
		}
	}
}

.gallery__cta{
	position: relative;
	display: block;
	width: 100%;
	height: 0;
	padding-bottom: 100%;
	background-color: $color-2;
	transition: all 0.2s ease;

	&:hover{
		background-color: $color-1;
	}

	h1,
	h2,
	h3{
		font-family: $montserrat;
		line-height: 1.2em;
		color: white;
	}

	h1{
		font-size: rem(36);
	}

	h2{
		font-size: rem(30);
	}

	h3{
		font-size: rem(24);
	}
	
	.wysi{
		padding: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		color: white;
	}
}

.section--seo{
	padding: 120px 0;
	background-color: white;

	.wysi{
		text-align: center;
		max-width: 768px;
		margin: 0 auto;
	}

	.grid{
		margin: -($gutter*2);
	}

	.col{
		padding: $gutter*2;

		&:last-child{
			align-self: flex-start;
		}
	}
}

.section--search-block{
	display: flex;
	padding: 120px 0;
	background: url(/assets/img/search-block.jpg);
	background-position: center center;
	background-size: cover;

	.col{
		&:first-child{
			flex: 0 0 auto;
		}

		&:nth-child(2){
			flex: 0 0 auto;
		}
	}

	.wysi{
		text-align: right;
	}

	h1{
		font-family: $montserrat;
		font-size: rem(50);
		line-height: 1.2em;
	}

	h2{
		font-family: $montserrat;
		font-size: rem(40);
		line-height: 1.2em;
	}

	h3{
		font-family: $montserrat;
		font-size: rem(30);
		line-height: 1.2em;
	}

	hr{
		margin: 0 60px;
		width: 1px;
		height: 200px;
		border: 0;
		background: rgba($color-2, 0.25);
	}

	form{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.icon{
		flex: 0 0 auto;
		width: 24px;
		height: 32px;
		fill: $color-1;
		margin-right: $gutter;
	}

	input{
		flex: 1 1 auto;
		width: 100%;
	}

	.btn{
		flex: 0 0 auto;
		margin-left: $gutter/2;
	}
}

@media screen and (max-width: 1199px){

}

@media screen and (max-width: 1023px){
	.slideshow{
		height: 600px;
	}

	.slideshow__slide-content{
		.wysi{
			max-width: 100%;
		}

		h1{
			font-size: rem(36);
		}

		h2{
			font-size: rem(30);
		}

		h3{
			font-size: rem(24);
		}
	}

	.slideshow__control{
		width: 20px;
		height: 38px;
	}

	.slideshow__control-icon{
		width: 20px;
		height: 38px;
	}

	.section--seo{
		padding: 60px 0;

		.grid{
			margin: -$gutter;
		}

		.col{
			padding: $gutter;
		}
	}

	.section--search-block{
		padding: 60px 0;

		.grid{
			flex-direction: column;
		}

		.col{
			&:nth-child(2){
				display: none;
			}
		}

		.wysi{
			text-align: center;
			margin-bottom: $gutter;
		}
	}
}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){
	.slideshow__slide-content{
		.wysi{
			text-align: center;
		}
	}
}

@media screen and (max-width: 639px){
	.section--search-block{
		form{
			flex-wrap: wrap;
		}

		.icon{
			display: none;
		}

		.btn{
			display: flex;
			justify-content: center;
			width: 100%;
			margin-left: 0;
			margin-top: $gutter/2;
		}
	}
}

@media screen and (max-width: 479px){
	
}